<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2>회원탈퇴</h2>
				<a @click="moveBack()" class="btnBack">이전 페이지</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- contents -->
		<div class="contents">
			<!-- withdrawal -->
			<div class="contCenter withdrawal">
				<strong>그 동안 "인사이트 노트"를 <br />이용해 주셔서 감사합니다.</strong>
				<p>향후 보다 나은 서비스 제공을 위해 탈퇴 사유 의견을 <br />작성해주시면 적극 반영하겠습니다.</p>
				<textarea
					title="탈퇴 사유를 입력해주세요."
					placeholder="탈퇴 사유를 입력해주세요."
					v-model="resignReason"
					maxlength="500"
				></textarea>
				<span class="checkbox">
					<input type="checkbox" id="chk0101" v-model="isAgree" />
					<label for="chk0101">회원 탈퇴 유의 사항을 모두 확인하였으며, 이에 동의 합니다.</label>
				</span>
				<div class="infoArea">
					<dl>
						<dt>회원탈퇴 유의사항</dt>
						<dd>&middot; 사용하고 계신 아이디는 탈퇴할 경우 재사용 및 복구가 불가능합니다.</dd>
						<dd>&middot; 회원탈퇴 시에는 인사이트 노트 로그인 하실 수 없습니다.</dd>
						<dd>
							&middot; 회원 탈퇴 후에도 등록한 메모, 댓글, 커뮤니티는 그대로 남아 있습니다. 삭제를 원하시는 반드시 탈퇴
							전 삭제해주시기 바랍니다.
						</dd>
					</dl>
				</div>
				<!-- btnBottom -->
				<div class="btnBottom">
					<button type="button" class="btn" @click="resign" :disabled="!isAgree || resignReason.length == 0">
						회원탈퇴
					</button>
				</div>
				<!--// btnBottom -->
			</div>
			<!--// withdrawal -->
		</div>
		<!--// contents -->
	</div>
	<!--// container -->
</template>
<script>
import { showSpinner, hideSpinner, hideMenu } from '@/utils/cmm';
import user from '@/api/user';
import auth from '@/api/auth';

export default {
	data() {
		return {
			isAgree: false,
			resignReason: '',
		};
	},
	mounted() {
		hideMenu();
	},
	methods: {
		moveBack() {
			this.$router.go(-1);
		},
		async resign() {
			if (!this.isAgree) {
				await this.$alert('회원 탈퇴에 동의해주세요.');
				return false;
			}

			if (!(await this.$confirm('회원 탈퇴를 진행하시겠습니까?', '회원 안내'))) return;
			try {
				showSpinner();
				await this.$alert('회원탈퇴 되었습니다.\r\n로그인 페이지로 이동합니다.');
				await user.resign(this.resignReason);
				await auth.logout();
				await this.$router.push({ name: 'login' });
			} catch (ex) {
				return await this.$alert(ex.message);
			} finally {
				hideSpinner();
			}
		},
	},
};
</script>
